import React from "react";

interface IContainer{
    style?: React.CSSProperties,
    children: any;
    dir?: "ltr" | "rtl";
    className?: string;
}

export default function Container(props: IContainer){
    return(
        <div className={props.className === "" ? "ms-Grid" : `ms-Grid ${props.className}`} dir={props.dir} style={{...props.style}}>
            {props.children}
      </div>
    );
}

Container.defaultProps = {
    dir : "ltr",
    className: ""
}