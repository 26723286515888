import axios from 'axios';

export const baseURL : string ="https://caif.altanet.it/service/";

const apiClient = axios.create({
    baseURL: baseURL
});

apiClient.interceptors.response.use(
    response => response.data,
    error => {
        if(error.response){
            return Promise.reject(error);
        }
        else if(error.response.status === 403){
            window.location.replace("/login");
        }
    }
);

export default apiClient;