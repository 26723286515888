import React, { useContext } from "react";
import PermissionDenied from "../page/PermissionDenied/PermissionDenied";
import { AuthProviderContext, IAuthProviderProps } from "../Provider/MyAuthProvider";
import LoginPage from "../page/LoginPage";

interface ICustomRequireAuth{
    role: string | string[];
    children: JSX.Element;
}


const generateRouteFromRole = (isAuthenticated: boolean, ruoloUtente: string, routeRole: string | string[], element: JSX.Element)=>{
    if(isAuthenticated){

        if(typeof routeRole === "string"){
            if(routeRole === ruoloUtente){
                return element;
            }else{
                return <PermissionDenied/>
            }
        }else{
            if(routeRole.includes(ruoloUtente)){
                return element;
            }else{
                return <PermissionDenied/>
            }
        }

    }else{
        return <LoginPage/>
    }
}


export default function CustomRequireAuth(props: ICustomRequireAuth){
    const {isAuthenticated, authState} = useContext<IAuthProviderProps>(AuthProviderContext);

    return generateRouteFromRole(isAuthenticated, authState !== undefined ? authState.ruolo : "", props.role, props.children);
}