import { Router, RouterProvider, createBrowserRouter } from "react-router-dom";
import CustomRequireAuth from "../components/CustomRequireAuth";
import { Suspense, useContext, useState } from "react";
import { applicationRoutes } from "../utility/applicationRoutes";
import LoginPage from "../page/LoginPage";
import {
  AuthProviderContext,
  IAuthProviderProps,
} from "../Provider/MyAuthProvider";
import PermissionDenied from "../page/PermissionDenied/PermissionDenied";
import ConfirmEmailPage from "../page/ConfirmEmailPage";

export default function RouterComponent() {
  const { authState } = useContext<IAuthProviderProps>(AuthProviderContext);


  const route = createBrowserRouter([
    {
      id: "loginPage",
      path: "/",
      element: <LoginPage />,
      index: true,
      loader: async () => {
        return document.title = "Progetto CAI - Login";
      },
    },
    {
      id: "confirmationEmail",
      path: "/confirmationEmail",
      element: <ConfirmEmailPage />,
      index: true,
      loader: async () => {
        return document.title = "Progetto CAI - Conferma Email";
      },
    },
    {
      id: "errorPage",
      path: "*",
      element: <PermissionDenied/>,
      loader: async () => {
        return document.title = "Progetto CAI - Permesso Negato";
      },
    },
  ],
  //{basename: process.env.REACT_APP_ROUTER_BASE}
  );

  const authRoute = createBrowserRouter(
    applicationRoutes.map((element: any, index: number) => {
        const LazyElement = element.component;
        if(element.role === authState?.ruolo){
            return {
                id: element.id,
                path: element.routerLink,
                name: element.title,
                hasIndex: true,//element.hasIndex,
                isChildren: false,
                loader: async () => {
                  return document.title = element.title;
                },
                element: (
                    <CustomRequireAuth role={element.role}>
                    <Suspense>
                        <LazyElement {...element.title}/>
                    </Suspense>
                    </CustomRequireAuth>
                ),
                errorElement: <PermissionDenied />,
                children: element.children?.map((el: any) => {
                    const LazyChild = el.component;
                    return {
                    id: el.id,
                    path: el.routerLink,
                    name: el.title,
                    hasIndex: true,//el.hasIndex,
                    isChildren: true,
                    loader: async () => {
                      return document.title = el.title;
                    },
                    errorElement: <PermissionDenied />,
                    element: (
                        <CustomRequireAuth role={element.role}>
                        <Suspense>
                            <LazyChild {...el.title}/>
                        </Suspense>
                        </CustomRequireAuth>
                    ),
                    };
                }),
            };
        }else{
            return {};
        }
       
      }),
      //{basename: process.env.REACT_APP_ROUTER_BASE}
  );

  return <RouterProvider router={authState !== undefined ? authRoute : route} future={{ v7_startTransition: true }}/>;
}
