import React from "react";

interface IRowProps{
    style?: React.CSSProperties;
    children?: any;
    className?: string;
}

export default function Row(props: IRowProps){
    return(
        <div className={`ms-Grid-row ${props.className}`} style={{...props.style}}>
            {props.children}
        </div>
    );

    
}

Row.defaultProps = {
    className :""
}