import { Stack, ProgressIndicator } from "@fluentui/react";
import Logo from "../assets/logo.png";

interface IPreloaderProps{
    title: string;
}

export default function Preloader(props: IPreloaderProps){
    return(
        <Stack verticalAlign="center" horizontalAlign="center" style={{height: "100%", gap: 8}}>
            <img className="animationPing" src={Logo} style={{width: "10%"}}/>
            <ProgressIndicator label={props.title}  description=""/>
        </Stack>
    );
}