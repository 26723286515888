import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { MyAuthProvider } from "./Provider/MyAuthProvider";
import RouterComponent from "./routing/RouterComponent";
import "office-ui-fabric-core/dist/css/fabric.css";
import "react-toastify/dist/ReactToastify.css";

import { ThemeProvider, initializeIcons } from "@fluentui/react";
import { myTheme } from "./utility/theme";
import { CookiesProvider } from "react-cookie";

initializeIcons();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <ThemeProvider theme={myTheme}>
        <MyAuthProvider>
          <RouterComponent />
        </MyAuthProvider>
      </ThemeProvider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
