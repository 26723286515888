import { createTheme } from "@fluentui/react";

export const myTheme = createTheme({
  palette: {
    themePrimary: '#bd0b27',
    themeLighterAlt: '#fcf3f4',
    themeLighter: '#f4d0d5',
    themeLight: '#eba9b3',
    themeTertiary: '#d75e70',
    themeSecondary: '#c5223a',
    themeDarkAlt: '#aa0a22',
    themeDark: '#8f091d',
    themeDarker: '#6a0615',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
  components: {
    DetailsRow: {
      styles: {
        fields: {
          display: "flex",
          alignItems: "center",
        },
      },
    },
  },
});
