import { ActionButton, AnimationClassNames, ContextualMenu, DirectionalHint, IconButton, Layer, Persona, PersonaSize, Spinner, SpinnerSize, mergeStyles, useTheme } from "@fluentui/react";
import Container from "../components/Container";
import Row from "../components/Row";
import Col from "../components/Col";
import Logo from "../assets/logo.png";//"../assets/logo-default-384x130.png";
import { useContext, useRef, useState } from "react";
import { IAuthProviderProps, AuthProviderContext } from "../Provider/MyAuthProvider";
import { Link, useNavigate } from "react-router-dom";

interface ILayoutProps{
    children: any;
    onClickHamburg: () => void;
}

export default function Layout(props: ILayoutProps){
    const {isAuthenticated, authState, onLogOut} = useContext<IAuthProviderProps>(AuthProviderContext);
    const [isOpenContextualMenu, setIsOpenContextualMenu] = useState<boolean>(false);
    const [activeSpinner, setActiveSpinner] = useState<boolean>(false);

    const navigate = useNavigate();

    const theme = useTheme();

    const contextualMenuRef = useRef(null);

    const contentClass = mergeStyles([
        {
          background: `linear-gradient(45deg, ${theme.palette.blueLight}, ${theme.palette.blue})`,//theme.palette.orangeLighter,
          color: theme.palette.white,
          lineHeight: '40px',
          padding: '8px 20px',
          display: "flex",
          alignItems: "center"
        },
        //AnimationClassNames.scaleUpIn100,
      ]);

    return(
        <Container style={{ height: "100dvh" }}>

            {isAuthenticated ? <IconButton iconProps={{iconName: "GlobalNavButton"}} className="ms-hiddenXxl" onClick={()=> props.onClickHamburg()}/> : undefined}

            {/* HEADER */}
                  {/*
            <Layer>
                <Row className={contentClass}>

                    <Col sm={6} md={6} lg={6} xl={6} style={{display: "flex", alignItems: "center"}}>
                        {isAuthenticated ? <IconButton iconProps={{iconName: "GlobalNavButton"}} className="ms-hiddenXxl" onClick={()=> props.onClickHamburg()}/> : undefined}
                        <a href={"/"} style={{height: 40}}><img src={Logo} onClick={()=>localStorage.setItem("path", "default")} height="40" alt="logoCai"/></a>
                    </Col>
                    <Col sm={6} md={6} lg={6} xl={6} style={{display: "grid", placeItems: "end"}}>
                        {isAuthenticated ?
                        <Persona
                            size={PersonaSize.size24}
                            text={authState?.username.charAt(0).toUpperCase() + authState?.username.slice(1)}
                            onClick={()=>{setIsOpenContextualMenu(prevState => !prevState)}} ref={contextualMenuRef}
                            styles={{root: {cursor: "pointer",  userFocus: theme.palette.accent, },
                            primaryText:{color: theme.palette.white, fontSize: 16, }}}
                        />
                        : undefined}
                        {
                            isAuthenticated && isOpenContextualMenu ?
                            <ContextualMenu
                                target={contextualMenuRef}
                                directionalHint={DirectionalHint.bottomCenter}
                                isBeakVisible={true}
                                beakWidth={20}
                                directionalHintFixed={false}
                                items={[
                                    {
                                        key: 'newItem',
                                        text: 'LogOut',
                                        onRender:()=>{
                                            return <ActionButton
                                            iconProps={{iconName: 'UserRemove'}}
                                            onClick={ async () => {
                                                    setActiveSpinner(true);
                                                    onLogOut();
                                                }}>
                                                Logout
                                            </ActionButton>
                                        }
                                    },
                                ]}
                                hidden={!isOpenContextualMenu}
                                onItemClick={()=>{}}
                                onDismiss={()=> setIsOpenContextualMenu(false)}
                                shouldFocusOnMount={true}
                                shouldFocusOnContainer={true}
                            />
                            :
                            undefined
                        }
                    </Col>

                </Row>
            </Layer>
            */}
            <Row style={{height: "100%"}}>
                <Col sm={12} md={12} lg={12} xl={12} style={{height: "100%", padding:"0px"}}>
                    {!activeSpinner ?
                        props.children :
                        <div style={{ height: "100%", display: "grid", placeItems: "center" }}>
                            <Spinner
                            label="Attendi un attimo..."
                            size={SpinnerSize.large}
                            ariaLive="assertive"
                            labelPosition="right"
                            />
                        </div>
                    }
                </Col>
            </Row>
        </Container>
    );
}

Layout.defaultProps={
    onClickHamburg: () => {}
}