import { useContext } from "react";
import { IAuthProviderProps, AuthProviderContext } from "../Provider/MyAuthProvider";
import { CustomResponse } from "../model/CustomResponse";
import apiClient from "../utility/httpClient";


export class UtenteService {

    authHeader = useContext<IAuthProviderProps>(AuthProviderContext);

    resourcePathLogin: string = "utente/login";

    resourcePathRegistrazione : string = "utente/registrazione/attivita";

    resourcePathCambioStato : string = "utente/cambiaStato";

    resourcePathConfirmationEmail : string = "utente/validate";

    resourcePathCheckConfirmationEmail : string = "utente/check";

    resourcePathGetInfoUtente : string = "utente";

    resourcePathCambioPassword: string = "utente/password";

    resourcePathRecuperoPasswordEmail : string = "utente/password/recupera";

    resourcePathChangeInfoUser : string = "utente/operatori";

    resourcePathCheckExistAndActiveUser : string = "utente/checkExistUserAndActive";


    sendEmailForRecoveryPassword(data: string): Promise<CustomResponse<any>>{
        return apiClient.put(`${this.resourcePathRecuperoPasswordEmail}/${data}`,{});
    }


    changePassword(idUtente: string, data: any): Promise<CustomResponse<any>>{
        return apiClient.put(`${this.resourcePathCambioPassword}/${idUtente}`, data, {
            headers: {
                Authorization: this.authHeader.authHeader()
            }
        });
    }

    getInfoUtente(idUtente: string) : Promise<CustomResponse<any>>{
        return apiClient.get(`${this.resourcePathGetInfoUtente}/${idUtente}`,{
            headers:{
                Authorization: this.authHeader.authHeader()
            }
        });
    }

    onConfirmEmail(uuid: string, otp: string, data: any) : Promise<CustomResponse<any>>{
        return apiClient.put(`${this.resourcePathConfirmationEmail}?uuid=${uuid}&otp=${otp}`,data);
    }

    onCheckConfirmEmail(uuid: string, otp: string) : Promise<CustomResponse<any>>{
        return apiClient.get(`${this.resourcePathCheckConfirmationEmail}?uuid=${uuid}&otp=${otp}`);
    }

    registraAttività(data: any): Promise<CustomResponse<any>>{
        return apiClient.post(`${this.resourcePathRegistrazione}`,data, {
            headers:{
                Authorization: this.authHeader.authHeader()
            }
        });
    }

    login(data: any): Promise<any>{
        return apiClient.post(`${this.resourcePathLogin}`, data);
    }

    attivaDisattiva(idUtente: string) : Promise<CustomResponse<any>>{
        return apiClient.put(`${this.resourcePathCambioStato}/${idUtente}`,{},{
            headers:{
                Authorization: this.authHeader.authHeader()
            }
        });
    }

    changeInfoUser(idUtente: string, data: any): Promise<CustomResponse<any>>{
        return apiClient.put(`${this.resourcePathChangeInfoUser}/${idUtente}`, data, {
            headers: {
                Authorization: this.authHeader.authHeader()
            }
        });
    }

    checkExistUser(idUtente: string) : Promise<CustomResponse<any>>{
        return apiClient.get(`${this.resourcePathCheckExistAndActiveUser}/${idUtente}`,{
            headers: {
                Authorization: this.authHeader.authHeader()
            }
        });
    }
}