import { createContext, useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { NavigateFunction } from "react-router-dom";
import { UtenteService } from "../services/UtenteService.service";

interface IAuthentication {
  authState: { [key: string]: any } | undefined;
  tokenType: string;
  token: string;
  expiresIn: number | undefined;
  refreshToken: string;
  refreshTokenExpireIn: number | undefined;
}

export interface IAuthProviderProps {
  onLogin: (data: IAuthentication, imgBase64: string, cookieExpiration: number ) => void;
  authState: { [key: string]: any } | undefined;
  authHeader: () => string;
  authForRefresh: () => string;
  onLogOut: () => void;
  isAuthenticated: boolean;
}

export const AuthProviderContext = createContext<IAuthProviderProps>({
  onLogin: (data: IAuthentication) => {},
  authState: undefined,
  authHeader: () => "",
  authForRefresh: () => "",
  onLogOut: () => {},
  isAuthenticated: false,
});

export function MyAuthProvider(props: any) {
  const [cookies, setCookie, removeCookie] = useCookies([
    "_authState",
    "_tokenType",
    "_token",
    "_refreshToken",
    "_expiresIn",
    "_refreshTokenExpireIn",
    "_isAuthenticated",
  ]);
  const [authState, setAuthState] = useState<{ [key: string]: any }>(
    cookies._authState
  );
  const [tokenType, setTokenType] = useState<string>(cookies._tokenType);
  const [token, setToken] = useState<string>(cookies._token);
  const [refreshToken, setRefreshToken] = useState<string>(cookies._refreshToken);
  const [expiresIn, setExpiresIn] = useState<number | undefined>(cookies._expiresIn);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(cookies._isAuthenticated);

  //const navigate = useNavigate();
  const utenteService = new UtenteService();

  const onLogin = (data: IAuthentication, imgBase64: string,cookieExpiration: number ) => {
    setAuthState(data.authState!);
    setTokenType(data.tokenType);
    setToken(data.token);
    setRefreshToken(data.refreshToken);
    setExpiresIn(data.expiresIn);
    var expirationDate = new Date(cookieExpiration);
    setCookie("_authState", data.authState,{expires: new Date(expirationDate.getFullYear(), expirationDate.getMonth(), expirationDate.getDate()), path: process.env.REACT_APP_ROUTER_BASE});
    setCookie("_token", data.token,{expires: new Date(cookieExpiration), path: process.env.REACT_APP_ROUTER_BASE });
    setCookie("_tokenType", data.tokenType,{expires: new Date(cookieExpiration), path: process.env.REACT_APP_ROUTER_BASE });
    setCookie("_refreshToken", data.refreshToken,{expires: new Date(cookieExpiration), path: process.env.REACT_APP_ROUTER_BASE  });
    setCookie("_expiresIn", data.expiresIn,{expires: new Date(cookieExpiration), path: process.env.REACT_APP_ROUTER_BASE });
    setCookie("_isAuthenticated", true,{expires: new Date(cookieExpiration), path: process.env.REACT_APP_ROUTER_BASE });
    localStorage.setItem("profilePicture", imgBase64)
    setIsAuthenticated(true);
  };

  const authHeader = () => {
    return `${tokenType} ${token}`;
  };

  const authForRefresh = () => {
    return `${tokenType} ${refreshToken}`;
  };

  const onRefreshToken = async () => {
    
    /*if(isAuthenticated){
      removeCookie("_token");
       try {
        const response = await loginService.refresh(
          `${tokenType} ${refreshToken}`
        );
        setCookie("_token", response.access_token);
        setToken(response.access_token);
      } catch (error) {
        if (error) {
          console.error("ERROR: ", error)
          return onLogOut("/");
        }
      }
    }*/
   
  };
  const MINUTE_MS = 60000

  useEffect(() => {
    if(authState){
      const intervalId =  setInterval(() => {       
       
            onRefreshToken();     
         
      },MINUTE_MS);
      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(()=>{
    (async()=>{
      if(authState){
          try {
            const result = await utenteService.checkExistUser(authState.id);
            
            if(!result.data.userExists || !result.data.userActive){
              onLogOut();
            }
          } catch (error) {
            if(error){
              onLogOut();
            }
          }
      }
    })();
  },[window.location.pathname])


  const onLogOut = async () => {
    const cookieNames = [
      '_authState',
      '_token',
      '_tokenType',
      '_isAuthenticated',
      '_refreshToken',
      '_expiresIn',
    ];
    
    /*if(process.env.REACT_APP_ROUTER_BASE){
      window.location.replace(process.env.REACT_APP_ROUTER_BASE);
    }*/ 

    window.location.replace("/");

    // Rimuovi i cookie in modo asincrono utilizzando Promise.all
    await Promise.all(cookieNames.map((cookieName: any) => removeCookie(cookieName,{path: process.env.REACT_APP_ROUTER_BASE})));

    // Rimuovi altri dati o esegui altre operazioni
    localStorage.removeItem('profilePicture');  
   
  };

  return (
    <AuthProviderContext.Provider
      value={{
        onLogin,
        authState,
        authHeader,
        onLogOut,
        authForRefresh,
        isAuthenticated,
      }}
    >
      {props.children}
    </AuthProviderContext.Provider>
  );
}
