import { useSearchParams } from "react-router-dom";
import Layout from "../layout/Layout";
import Preloader from "../components/Preloader";
import {
  PrimaryButton,
  Stack,
  TextField,
  Image,
  ImageFit,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { UtenteService } from "../services/UtenteService.service";
import Container from "../components/Container";
import ImgConfirm from "../assets/bgConfirm.jpg";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";

export default function ConfirmEmailPage() {
  const [searchParams] = useSearchParams();
  const [isAlreadyAuth, setIsAlreadyAuth] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);

  const uuid = searchParams.get("uuid");
  const otp = searchParams.get("otp");

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Il campo è obbligatorio"),
    password: Yup.string()
      .required("Il campo è obbligatorio")
      .min(8, "La lunghezza minima è di 8 caratteri")
      .matches(/^(?=.*[A-Z])/, "Deve contenere almeno un carattere maiuscolo")
      .matches(/^(?=.*[0-9])/, "Deve contenere almeno un numero")
      .matches(
        /^(?=.*[!@#$%^&*(),.?":{}|<>])/,
        "Deve contenere almeno un carattere speciale"
      ),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), undefined],
        "Le password devono essere identiche"
      )
      .required("Il campo è obbligatorio"),
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const utenteService = new UtenteService();

  useEffect(() => {
    (async () => {
      try {
        if (uuid && otp) {
          const responseCheck = await utenteService.onCheckConfirmEmail(uuid, otp);
          setShowForm(true);
          setIsAlreadyAuth(responseCheck.data);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    setShowForm(false);
    try {
      return await toast.promise(
        utenteService.onConfirmEmail(uuid!, otp!, data),
        {
          pending: {
            render() {
              return "Invio richiesta in corso...";
            },
            role: "alert",
          },
          success: {
            render(response: any) {
              return `L'attività è stata registrata correttamente.`;
            },
            role: "alert",
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
            onClose: () => window.location.replace("/"),
          },
          error: {
            render(responseError: any) {
              return responseError.data.response.data.message;
            },
            role: "alert",
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
            onClose: () => setShowForm(true),
          },
        }
      );
    } catch (error) {
      
    }
    
  });

  return (
    <Layout>
      <Container style={{ height: "100%" }}>
        <ToastContainer pauseOnFocusLoss={false}/>
        {!showForm ? 
          <Preloader title={"Un attimo di pazienza..."} />
         : !isAlreadyAuth ? 
          <Stack
            horizontal
            verticalAlign="center"
            horizontalAlign="center"
            style={{ height: "100%" }}
            tokens={{ childrenGap: 16 }}
          >
            <Stack.Item
              className={"ms-hiddenLgDown"}
              style={{ height: "100%", display: "grid", placeItems: "center" }}
            >
              <Image
                src={ImgConfirm}
                imageFit={ImageFit.centerContain}
                height={"90%"}
                width={800}
                alt="imageConfirmPage"
                style={{ borderRadius: 32 }}
              />
            </Stack.Item>
            <Stack.Item grow={0.5}>
              <h1>Verifica Account</h1>
              <form
                autoComplete="off"
                noValidate
                onSubmit={onSubmit}
                style={{ height: "100%" }}
              >
                <TextField
                  label="Username"
                  type="text"
                  placeholder="Inserisci il tuo username..."
                  errorMessage={errors.username && errors.username.message}
                  onChange={(ev, newValue) => {
                    setValue(`username`, newValue!);
                  }}
                />
                <TextField
                  label="Password"
                  canRevealPassword
                  type={"password"}
                  placeholder="Inserisci la password..."
                  errorMessage={errors.password && errors.password.message}
                  onChange={(ev, newValue) => {
                    setValue(`password`, newValue!);
                  }}
                />
                <TextField
                  label="Conferma Password"
                  canRevealPassword
                  type={"password"}
                  placeholder="Inserisci nuovamente la password..."
                  errorMessage={
                    errors.confirmPassword && errors.confirmPassword.message
                  }
                  onChange={(ev, newValue) => {
                    setValue(`confirmPassword`, newValue!);
                  }}
                />
                <div
                  style={{ display: "grid", placeItems: "center", padding: 16 }}
                >
                  <PrimaryButton type="submit" text={"Verifica account"} />
                </div>
              </form>
            </Stack.Item>
          </Stack>
         : 
          <div
            style={{ display: "grid", placeItems: "center", height: "inherit" }}
          >
            <h3>Questo utente è stato già verificato!</h3>
          </div>
        }
      </Container>
    </Layout>
  );
}
