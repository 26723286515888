import React from "react";
import { IApplicationRoute } from "../model/ApplicationRoutes";

//ROLE_SUPERADMIN

export const applicationRoutes: IApplicationRoute[] = [
    /* SUPER ADMIN */
    {
        id: "superAdmin",
        title: "Amministrazione - Mappa",
        routerLink: "/",
        name: "",
        component: React.lazy(()=> import("../page/SuperAdmin/MapPage")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_SUPERADMIN",
        children:[
            {
                id: "superAdminDashboard",
                title: "Amministrazione - Dashboard",
                routerLink: "/dashboard",
                name: "",
                component: React.lazy(()=> import("../page/SuperAdmin/Dashboard")),
                isPublic: false,
                hasIndex: true,
                role: "ROLE_SUPERADMIN",
            },
            {
                id: "adminsuperAdminProfilo",
                title: "Amministrazione - Profilo",
                routerLink: "/profilo",
                name: "",
                component: React.lazy(()=> import("../page/ProfiloPage")),
                isPublic: false,
                hasIndex: false,
                role: "ROLE_SUPERADMIN",
            },
            {
                id: "superAdminEventi",
                title: "Amministrazione - Calendario Eventi",
                routerLink: "/calendarioeventi",
                name: "",
                component: React.lazy(()=> import("../components/MyFullCalendar/EventiCalendar")),
                isPublic: false,
                hasIndex: true,
                role: "ROLE_SUPERADMIN",
            },
        ]
    },

    {
        id: "superAdminGestioneAdmin",
        title: "Amministrazione - Gestione Amministratori",
        routerLink: "/users",
        name: "",
        component: React.lazy(()=> import("../page/SuperAdmin/GestioneAdmin/ListAdmin")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_SUPERADMIN",
        children: [
            {
                id: "superAdminGestioneAdminDettaglio",
                title: "Amministrazione - Dettaglio Amministratore",
                routerLink: "/users/:id",
                name: "",
                component: React.lazy(()=> import("../page/SuperAdmin/GestioneAdmin/DettaglioAdmin")),
                isPublic: false,
                hasIndex: false,
                role: "ROLE_SUPERADMIN",
            }
        ]
    },
    {
        id: "superAdminPuntiInteresse",
        title: "Punti Interesse",
        routerLink: "/puntinteresse",
        name: "",
        component: React.lazy(()=> import("../page/SuperAdmin/PuntiInteresse/ListaPuntiInteresse")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_SUPERADMIN",
        children:[
            {
                id: "superAdminDetailPuntinteresse",
                title: "Dettaglio Punti Interesse",
                routerLink: "dettaglioPuntinteresse/:id",
                name: "",
                component: React.lazy(()=> import("../page/SuperAdmin/PuntiInteresse/DettaglioPuntiInteresse")),
                isPublic: false,
                hasIndex: false,
                role: "ROLE_SUPERADMIN",
            }
        ]
    },

    {
        id: "superAdminListaUtentiApp",
        title: "Lista Utenti App",
        routerLink: "/appusers",
        name: "",
        component: React.lazy(()=> import("../page/SuperAdmin/UserApp/ListaUtentiApp")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_SUPERADMIN",
    },
    {
        id: "superAdminListaEventi",
        title: "Lista Eventi",
        routerLink: "/events",
        name: "",
        component: React.lazy(()=> import("../page/SuperAdmin/Eventi/ListaEventi")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_SUPERADMIN",
        children: [
            {
                id: "superAdminDettaglioEvento",
                title: "Dettaglio Evento",
                routerLink: "dettaglioevent/:id",
                name: "",
                component: React.lazy(()=> import("../page/SuperAdmin/Eventi/Dettaglio/DettaglioEvento")),
                isPublic: false,
                hasIndex: false,
                role: "ROLE_SUPERADMIN",
            }
        ]
    },



    /* ADMIN */
    {
        id: "admin",
        title: "Amministrazione - Mappa",
        routerLink: "/",
        name: "",
        component: React.lazy(()=> import("../page/Admin/MapPage")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_AMMINISTRATORE",
        children:[
            {
                id: "adminDashboard",
                title: "Amministrazione - Dashboard",
                routerLink: "/dashboard",
                name: "",
                component: React.lazy(()=> import("../page/Admin/Dashboard")),
                isPublic: false,
                hasIndex: true,
                role: "ROLE_AMMINISTRATORE",
            },
            {
                id: "adminProfilo",
                title: "Amministrazione - Profilo",
                routerLink: "/profilo",
                name: "",
                component: React.lazy(()=> import("../page/ProfiloPage")),
                isPublic: false,
                hasIndex: false,
                role: "ROLE_AMMINISTRATORE",
            },
            {
                id: "adminEventi",
                title: "Amministrazione - Calendario Eventi",
                routerLink: "/calendarioeventi",
                name: "",
                component: React.lazy(()=> import("../components/MyFullCalendar/EventiCalendar")),
                isPublic: false,
                hasIndex: true,
                role: "ROLE_AMMINISTRATORE",
            },
        ]
    },
    {
        id: "adminCulturali",
        title: "Amministrazione - Operatori Culturali",
        routerLink: "/operatoriculturali",
        name: "",
        component: React.lazy(()=> import("../page/Admin/Culturali/OperatoriCulturali")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_AMMINISTRATORE",
        children: [
            {
                id: "adminDetailOperatoriCulturali",
                title: "Amministrazione - Dettaglio Operatore Culturale",
                routerLink: "dettaglioOperatoreCulturale/:id",
                name: "",
                component: React.lazy(()=> import("../page/Admin/Culturali/Dettaglio/DettaglioOperatoriCulturali")),
                isPublic: false,
                hasIndex: false,
                role: "ROLE_AMMINISTRATORE",
            }
        ]
    },
    {
        id: "adminPuntiInteresseCulturali",
        title: "Amministrazione - Punti d'interesse culturali",
        routerLink: "/puntinteresseculturali",
        name: "",
        component: React.lazy(()=> import("../page/Admin/Culturali/PuntiInteresseCulturali")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_AMMINISTRATORE",
        children: [
            {
                id: "Amministrazione - Dettaglio Punti d'interesse culturali",
                title: "Dettaglio Punti d'interesse culturali",
                routerLink: "dettagliopuntointeresseculturale/:id",
                name: "",
                component: React.lazy(()=> import("../page/Admin/Culturali/Dettaglio/DettaglioPuntiInteresseCulturali")),
                isPublic: false,
                hasIndex: true,
                role: "ROLE_AMMINISTRATORE",
            }
        ]
    },
    {
        id: "adminCommerciali",
        title: "Amministrazione - Commerciali",
        routerLink: "/commerciali",
        name: "",
        component: React.lazy(()=> import("../page/Admin/Commerciali/OperatoriCommerciali")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_AMMINISTRATORE",
        children:[
            {
                id: "adminDetailOperatoriCommerciali",
                title: "Amministrazione - Dettaglio Operatore Commerciale",
                routerLink: "dettaglioOperatoreCommerciale/:id",
                name: "",
                component: React.lazy(()=> import("../page/Admin/Commerciali/Dettaglio/DettaglioOperatoriCommerciali")),
                isPublic: false,
                hasIndex: false,
                role: "ROLE_AMMINISTRATORE",
            }
        ]
    },
    {
        id: "adminPuntiInteresseCommerciali",
        title: "Amministrazione - Punti d'interesse commerciali",
        routerLink: "/puntinteressecommerciali",
        name: "",
        component: React.lazy(()=> import("../page/Admin/Commerciali/PuntiInteresseCommerciali")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_AMMINISTRATORE",
        children: [
            {
                id: "Amministrazione - Dettaglio Punti d'interesse commerciali",
                title: "Dettaglio Punti d'interesse commerciali",
                routerLink: "dettagliopuntointeressecommerciale/:id",
                name: "",
                component: React.lazy(()=> import("../page/Admin/Commerciali/Dettaglio/DettaglioPuntiInteresseCommerciali")),
                isPublic: false,
                hasIndex: true,
                role: "ROLE_AMMINISTRATORE",
            }
        ]
    },
    
    /*{
        id: "adminGestioneCategorie",
        title: "Amministrazione - Gestione Categorie",
        routerLink: "/gestioneCategorie",
        name: "",
        component: React.lazy(()=> import("../page/Admin/Categorie/GestioneCategorie")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_AMMINISTRATORE",
    },*/
    {
        id: "adminListaUtentiApp",
        title: "Amministrazione - Lista Utenti App",
        routerLink: "/listautentiapp",
        name: "",
        component: React.lazy(()=> import("../page/Admin/UserApp/ListaUtentiApp")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_AMMINISTRATORE",
    },
    {
        id: "adminListaEventi",
        title: "Amministrazione - Lista Eventi",
        routerLink: "/listaeventi",
        name: "",
        component: React.lazy(()=> import("../page/Admin/Eventi/ListaEventi")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_AMMINISTRATORE",
        children: [
            {
                id: "Amministrazione - Dettaglio Evento",
                title: "Amministrazione - Dettaglio Evento",
                routerLink: "dettaglioevento/:id",
                name: "",
                component: React.lazy(()=> import("../page/Admin/Eventi/Dettaglio/DettaglioEvento")),
                isPublic: false,
                hasIndex: false,
                role: "ROLE_AMMINISTRATORE",
            }
        ]
    },
    /* ASSOCIAZIONE CULTURALE */
    {
        id: "attivitàCulturaleClaustri",
        title: "Culturale - Claustri",
        routerLink: "/",
        name: "",
        component: React.lazy(()=> import("../page/AttivitàCulturale/Claustri/ListaClaustri")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_CULTURALE",
        children: [
            {
                id: "Associazione Culturale - Profilo",
                title: "Culturale - Profilo",
                routerLink: "/profilo",
                name: "",
                component: React.lazy(()=> import("../page/ProfiloPage")),
                isPublic: false,
                hasIndex: false,
                role: "ROLE_CULTURALE",
            },
            {
                id: "Associazione Culturale - Dettaglio Claustro",
                title: "Culturale - Dettaglio Claustro",
                routerLink: "dettaglioclaustro/:id",
                name: "",
                component: React.lazy(()=> import("../page/AttivitàCulturale/Claustri/Dettaglio/DettaglioClaustro")),
                isPublic: false,
                hasIndex: true,
                role: "ROLE_CULTURALE",
            },
            {
                id: "Associazione Culturale - Calendario Eventi Condiviso",
                title: "Culturale - Calendario Eventi Condiviso",
                routerLink: "/calendarioeventi",
                name: "",
                component: React.lazy(()=> import("../components/MyFullCalendar/EventiCalendar")),
                isPublic: false,
                hasIndex: true,
                role: "ROLE_CULTURALE",
            },
        ]
    },
    {
        id: "attivitàCulturaleEventi",
        title: "Culturale - Eventi",
        routerLink: "/listaeventi",
        name: "",
        component: React.lazy(()=> import("../page/AttivitàCulturale/Eventi/ListaEventi")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_CULTURALE",
        children: [
            {
                id: "Culturale - Dettaglio Evento",
                title: "Culturale - Dettaglio Claustro Evento",
                routerLink: "dettaglioevento/:id",
                name: "",
                component: React.lazy(()=> import("../page/AttivitàCulturale/Eventi/Dettaglio/DettaglioEvento")),
                isPublic: false,
                hasIndex: false,
                role: "ROLE_CULTURALE",
            }
        ]
    },
    /* ATTIVITA' COMMERCIALE */
    {
        id: "attivitàCommercialeEventi",
        title: "Commerciale - Gestione Eventi",
        routerLink: "/listaeventi",
        name: "",
        component: React.lazy(()=> import("../page/AttivitàCommerciale/EventiCommerciale/EventiCommerciale")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_COMMERCIALE",
        children:[
            {
                id: "Commerciale - Dettaglio evento",
                title: "Commerciale - Dettaglio Evento",
                routerLink: "dettaglioevento/:id",
                name: "",
                component: React.lazy(()=> import("../page/AttivitàCommerciale/EventiCommerciale/Dettaglio/DettaglioEventoCommerciale")),
                isPublic: false,
                hasIndex: true,
                role: "ROLE_COMMERCIALE",
            },
          
        ]
    },
    {
        id: "attivitàCommercialePuntoInteresse",
        title: "Commerciale - Gestione punto d'interesse",
        routerLink: "/",
        name: "",
        component: React.lazy(()=> import("../page/AttivitàCommerciale/PuntoInteresse/GestionePuntoInteresse")),
        isPublic: false,
        hasIndex: true,
        role: "ROLE_COMMERCIALE",
        children:[
            {
                id: "attivitàCommercialeProfilo",
                title: "Commerciale - Profilo",
                routerLink: "/profilo",
                name: "",
                component: React.lazy(()=> import("../page/ProfiloPage")),
                isPublic: false,
                hasIndex: false,
                role: "ROLE_COMMERCIALE",
            },
            {
                id: "Attività Commerciale - Dettaglio punto d'interesse",
                title: "Commerciale - Dettaglio Punto Interesse",
                routerLink: "dettagliopuntointeresse/:id",
                name: "",
                component: React.lazy(()=> import("../page/AttivitàCommerciale/PuntoInteresse/Dettaglio/DettaglioPuntoInteresse")),
                isPublic: false,
                hasIndex: true,
                role: "ROLE_COMMERCIALE",
            },
            {
                id: "Commerciale - Calendario Eventi Condiviso",
                title: "Commerciale - Calendario Eventi Condiviso",
                routerLink: "/calendarioeventi",
                name: "",
                component: React.lazy(()=> import("../components/MyFullCalendar/EventiCalendar")),
                isPublic: false,
                hasIndex: true,
                role: "ROLE_COMMERCIALE",
            },
        ]
    },
];