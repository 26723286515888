import React from "react";

interface ICol{
    sm: number;
    md: number;
    lg: number;
    xl: number;
    xxl?: number;
    xxxl?: number;
    children: any;
    style?: React.CSSProperties;
    className?: string;
}

export default function Col(props: ICol){
    return(
        <div className={`${props.className !== "" ? props.className: ""} ms-Grid-col ms-sm${props.sm} ms-md${props.md} ms-lg${props.lg} ms-xl${props.xl} ${props.xxl ? `ms-xxl${props.xxl}` : ""} ${props.xxxl ? `ms-xxxl${props.xxxl}` : ""}`} style={{...props.style}}>
            {props.children}
        </div>
    );
}