import { PrimaryButton, Stack, TextField, Image, ImageFit, ActionButton, Dialog, DialogFooter, DefaultButton, DialogType } from "@fluentui/react";
import Col from "../components/Col";
import {yupResolver} from "@hookform/resolvers/yup";
import Layout from "../layout/Layout";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import {
  AuthProviderContext,
  IAuthProviderProps,
} from "../Provider/MyAuthProvider";
import { UtenteService } from "../services/UtenteService.service";
//import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import BgLogin from "../assets/bgLogin.jpg";
import background from "../assets/bg.png";
import logoorz from "../assets/Logo CAI svg orizontale.svg";
import loghi from "../assets/loghi_.png";

var randomnumber = Math.floor(Math.random() * 999);
var testrandom = "autocomplte-"+randomnumber.toString();


export default function LoginPage() {
  const { onLogin } = useContext<IAuthProviderProps>(AuthProviderContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const utenteService = new UtenteService();

  useEffect(()=>{
    localStorage.removeItem("path");
  },[])

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Il campo è obbligatorio!"),
    password: Yup.string().required("Il campo è obbligatorio!"),
  });
  const { handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = handleSubmit( async (data) => {
    try {
        return await handleLogin(data);
    } catch (error) {
        console.error("error login: ", error)
    }
  });

  const handleLogin = async (data: any) => {
    return await toast.promise(utenteService.login(data),{
        pending: {
            render(){
                return "Invio richiesta in corso..."
            },
            role: "alert"
        },
        success: {
            render(response: any){
              onLogin({
                tokenType: "Bearer",
                token: response.data.data.access_token,
                authState: response.data.data.utente,
                expiresIn: 999999,
                refreshToken: "",
                refreshTokenExpireIn: 999999999
              },"", response.data.data.tokenExpireDate)
                return `Attendi un attimo e sarai reindirizzato!`;
            },
            role: "alert",
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
            onClose: () => window.location.href = "/"//"testPage"
        },
        error: {
            render(responseError: any){
              if(responseError.data.response && responseError.data.response.data){
                return responseError.data.response.data.message;
              }else{
                return "Ops..Sembra ci sia qualche problema con i nostri servizi!";
              }
            },
            role: "alert",
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
           // onClose:() => setDisabled(false)
        }
    })
  };
  return (
    <Layout>
        <ToastContainer pauseOnFocusLoss={false}/>
      <Stack verticalAlign="center" style={{ height: "100%", backgroundImage: `url(${background})` }} className="homebg">
        {/* FORM */}
        <Stack.Item>
          <Stack
            horizontal
            verticalAlign="center"
            horizontalAlign="center"
            style={{ height: "100%", }}
            tokens={{ childrenGap: 16 }}
          >
<Stack.Item>
        <Image
    src={logoorz}
    width={400}
    alt="logo"
  />
</Stack.Item>
            </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack
            horizontal
            verticalAlign="center"
            horizontalAlign="center"
            style={{ height: "100%", }}
            tokens={{ childrenGap: 16 }}
          >
            {/*
            <Stack.Item
              className={"ms-hiddenLgDown"}
              style={{ height: "100%", display: "grid", placeItems: "center", }}
            >
              <Image
                src={BgLogin}
                imageFit={ImageFit.cover}
                height={"100%"}
                width={800}
                alt="imageConfirmPage"
                style={{ borderRadius: 32 }}
              />
            </Stack.Item>
  */}
            <Stack.Item grow={0} className="homelogin">
            <Col sm={12} md={12} lg={12} xl={12} xxl={12} style={{ padding: 16 }}>
                <h1 style={{margin:0}}>Accedi al portale CAI</h1>
            </Col>
            <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12} style={{ padding: 16 }}>
              <TextField
                label="Username"
                autoComplete={testrandom}
                placeholder={"Inserisci username..."}
                required={true}
                errorMessage={errors.username && errors.username.message}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setValue("username", newValue);
                  }
                }}
              />
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12} style={{ padding: 16 }}>
              <TextField
                label="Password"
                autoComplete={testrandom}
                placeholder={"Inserisci password..."}
                required={true}
                type={"password"}
                canRevealPassword
                errorMessage={errors.password && errors.password.message}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setValue("password", newValue);
                  }
                }}
              />
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12} style={{ display: "grid", placeItems: "center", marginTop: 32, gap: 16 }}>
              <PrimaryButton type="submit" text={"Accedi"} /*style={{backgroundColor: theme.palette.orangeLight, border: theme.palette.orange}}*/ />
              {/*<Link to={""}>Hai dimenticato la password?</Link>*/}
                  <ActionButton iconProps={{ iconName: "mail" }} text="Hai dimenticato la password?" autoFocus={true} onClick={() => setShowModal(true)} />


                </Col>
              </form>
              <Col sm={12} md={12} lg={12} xl={12} xxl={12} style={{ padding: 16 }}>
              <Image
    src={loghi}
    style={{width: "100%", maxWidth: 760, marginTop:16, marginBottom:8}}
    alt="loghi"
  />
                </Col>
            </Stack.Item>
            </Stack>
        </Stack.Item>
      </Stack>
      {showModal ? <ModalSendEmailForRecoveryPassword isOpen={showModal} setIsOpen={()=> setShowModal(false)} service={utenteService}/> : undefined}
    </Layout>
  );
}

interface IModalSendEmailForRecoveryPasswordProps{
  isOpen: boolean;
  setIsOpen: () => void;
  service: UtenteService
}

function ModalSendEmailForRecoveryPassword(props: IModalSendEmailForRecoveryPasswordProps) {

  const dialogContentProps = {
    showCloseButton: false,
    type: DialogType.normal,
    title: "Recupera Password"
    };
    const dialogModalProps = {
    isBlocking: true,
    };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Inserisci un email valida").matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "La mail inserita non è valida").required("Il campo è obbligatorio"),
  });
  const { handleSubmit, reset, formState: { errors }, setValue } = useForm({
    defaultValues: {
      email: ""
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = handleSubmit(async(data)=>{
    return await toast.promise(props.service.sendEmailForRecoveryPassword(data.email), {
      pending: {
        render(){
            return "Invio richiesta in corso..."
        },
        role: "alert"
    },
    success: {
        render(response: any){
            return `Ti è stata appena inviata una mail per il recupero password!`; //response.data.data.msg;
        },
        role: "alert",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: "light",
        onClose: () => props.setIsOpen()
    },
    error: {
        render(responseError: any){
            return responseError.data.response.data.message;//`C'è stato un errore durante la creazione dell'unità immobiliare!`;//responseError.data.response.data.error
        },
        role: "alert",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: "light",
       // onClose:() => setDisabled(false)
    }
    });
  })
  return(
    <Dialog hidden={!props.isOpen} onDismiss={() => props.setIsOpen()}  modalProps={dialogModalProps} dialogContentProps={dialogContentProps}>
      <form autoComplete="off" noValidate onSubmit={onSubmit}>
          <TextField
            label="Email"
            type={"email"}
            placeholder="Inserisci la tua email..."
            errorMessage={errors.email && errors.email.message}
            onChange={(ev, newValue) => {
              setValue(`email`, newValue!);
            }}
          />
        <DialogFooter>
            <PrimaryButton type="submit" text="Richiedi"/>
            <DefaultButton onClick={() => {props.setIsOpen(); reset();}} text="Annulla"/>
        </DialogFooter>
    </form>
  </Dialog>
  );
}
