import React from "react";
import Container from "../../components/Container";
import { ActionButton } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";

export default function PermissionDenied(){

    const navigate = useNavigate();
    return(
        <Layout>
            <Container style={{height: "100%", display: "grid", placeItems: "center"}}>
                <h1>Non ti è consentito accedere a questa risorsa</h1>
                <ActionButton iconProps={{iconName: "NavigateBack"}} text="Torna indietro!" onClick={()=> navigate(-1) }/>
            </Container>
        </Layout>
    );
}